import * as bootstrap from "bootstrap"
import Typed from "typed.js";

(() => {
    "use strict"

    // Theme Picker
    const getStoredTheme = () => localStorage.getItem("theme")
    const setStoredTheme = theme => localStorage.setItem("theme", theme)
    const getPreferredTheme = () => {
        const storedTheme = getStoredTheme()
        if (storedTheme) {
            return storedTheme
        }
        // return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
        return "dark";
    }
    const setTheme = theme => {
        // if (theme === "auto" && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        if (theme === "auto") {
            document.documentElement.setAttribute("data-bs-theme", "dark")
        } else {
            document.documentElement.setAttribute("data-bs-theme", theme)
        }
    }
    setTheme(getPreferredTheme())
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
        const storedTheme = getStoredTheme()
        if (storedTheme !== "light" && storedTheme !== "dark") {
            setTheme(getPreferredTheme())
        }
    })
    document.querySelector(".toggle-theme")?.addEventListener("click", () => {
        const targetTheme = getStoredTheme() === "light" ? "dark" : "light";
        setTheme(targetTheme)
        setStoredTheme(targetTheme)
    })

    // Typed
    if (document.querySelector(".intro-name")) {
        new Typed(".intro-name span.variable", {
            strings: ["rápidos", "úteis", "seguros", "flexíveis", "disponíveis", "eficientes"],
            backDelay: 1000,
            typeSpeed: 90,
            startDelay: 1000,
            showCursor: true,
            contentType: "text",
            loop: true
        });
    }

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

    // Copy link on click header
    // Select all h1 to h6 elements
    const postEntry = document.querySelectorAll(".post.intro, article.single, .content-page");
    if (postEntry.length) {
        const headers = [];
        postEntry.forEach(entry => {
            const entryHeaders = entry.querySelectorAll('h1, h2, h3, h4, h5, h6');
            headers.push(...entryHeaders);
        });
        headers.forEach(heading => {
            heading.addEventListener("click", () => {
                const url = heading.getAttribute("data-link");
                if (url) {
                    navigator.clipboard.writeText(url).then(() => {
                        const tooltipInstance = bootstrap.Tooltip.getInstance(heading);
                        tooltipInstance.hide();
                    }).catch(err => {
                        console.log("Copy to clipboard error", err);
                    });
                }
            });
        });
    }

    // Contact Form
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation")
    Array.from(forms).forEach(form => {
        form.addEventListener("submit", event => {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }
            form.classList.add("was-validated")
        }, false)
    });

    if (document.querySelector(".contact-form-section")) {
        const contactForm = {
            section: document.querySelector(".contact-form-section"),
            collapse: bootstrap.Collapse.getOrCreateInstance(".contact-form-section", {toggle: false}),
            form: document.querySelector(".contact-form-section > form"),
            status: {
                success: document.querySelector(".contact-form-section > .status > .success"),
                error: document.querySelector(".contact-form-section > .status > .error"),
                progress: document.querySelector(".contact-form-section > .status > .progressmsg")
            }
        }

        const formSumissionErrorHandlder = () => {
            contactForm.collapse.hide();
            contactForm.section.addEventListener('hidden.bs.collapse', () => {
                contactForm.status.progress.classList.add("d-none");
                contactForm.status.success.classList.add("d-none");
                contactForm.status.error.classList.remove("d-none");
                contactForm.form.classList.remove("d-none");
                contactForm.collapse.show();
            }, {once: true});
        }

        const formSumissionSuccessHandlder = () => {
            contactForm.collapse.hide();
            contactForm.section.addEventListener('hidden.bs.collapse', () => {
                contactForm.status.progress.classList.add("d-none");
                contactForm.status.success.classList.remove("d-none");
                contactForm.status.error.classList.add("d-none");
                contactForm.form.classList.add("d-none");
                contactForm.collapse.show();
            }, {once: true});
        }

        contactForm.form?.addEventListener("submit", event => {
            event.preventDefault()
            event.stopPropagation()
            if (!event.target.checkValidity()) {
                return;
            }
            const data = new FormData(event.target);
            const url = event.target.action;

            contactForm.collapse.hide();
            contactForm.section.addEventListener('hidden.bs.collapse', () => {
                // Hide the section, hide form, reset status, add progress message
                contactForm.form.classList.add("d-none");
                contactForm.status.error.classList.add("d-none");
                contactForm.status.success.classList.add("d-none");
                contactForm.status.progress.classList.remove("d-none");
                // Show the section again
                contactForm.collapse.show();
                contactForm.section.addEventListener('shown.bs.collapse', () => {
                    // Submit the form
                    fetch(url, {
                        body: data,
                        method: "post"
                    }).then(response => {
                        response.text().then(value => {
                            if (value === "1") {
                                formSumissionSuccessHandlder();
                                return;
                            }
                            formSumissionErrorHandlder();
                        })
                    }).catch(formSumissionErrorHandlder);
                }, {once: true});
            }, {once: true});
        });
    }

})()
